import { Dispatch, useContext, useEffect, useMemo, useState } from "react";
import { registerLicense, enableRipple } from "@syncfusion/ej2-base";
import { useGetMyCustomerQuery } from "../../../services/api/__generated__/backend_gateway-types";
import { DiffParams } from "../diff_params";
import { ApiContext } from "../../../contexts/api/context";
import { DemandDiffTableComponent } from "./table_component";
import { DemandFromCsv, DemandWithSkuTags } from "../demand_from_csv";
import { AppearanceContext } from "../../../contexts/appearance/context";

export const DemandDiffTableContainer = (props: {
  diffParams: DiffParams;
  demand0: DemandFromCsv;
  demand0Loading: boolean;
  demand1: DemandFromCsv;
  demand1Loading: boolean;
  skuTagsLoading: boolean;
  setPivotObject: Dispatch<any>;
}) => {
  const {
    diffParams,
    demand0,
    demand0Loading,
    demand1,
    demand1Loading,
    skuTagsLoading,
    setPivotObject,
  } = props;
  const { apiService } = useContext(ApiContext);
  const { modeString } = useContext(AppearanceContext);
  const {
    data: customerData,
    loading: customerLoading,
    error: customerError,
  } = useGetMyCustomerQuery({
    client: apiService.getClient(),
  });

  if (customerError) {
    console.error(
      "DemandDiffTableContainer: useGetMyCustomerQuery fetch error",
      customerError
    );
  }

  const ej2License = customerData?.getMyCustomer?.ej2License || null;
  const [hasLicence, setHasLicense] = useState<boolean>(false);

  useEffect(() => {
    if (ej2License !== null) {
      enableRipple(false);
      registerLicense(ej2License);
      setHasLicense(() => true);
    }
  }, [ej2License]);

  const { minDate, maxDate, runId1 } = diffParams;

  const demand0Data = demand0.getDemand(
    minDate,
    maxDate,
    diffParams.aggregatePeriodType
  );
  const demand0DataLength = demand0Data.length;
  const demand1Data = demand1.getDemand(
    minDate,
    maxDate,
    diffParams.aggregatePeriodType
  );
  const demand01ataLength = demand1Data.length;

  const demand: DemandWithSkuTags[] = useMemo(() => {
    if (runId1 && demand0DataLength > 0 && demand01ataLength > 0) {
      const demandIds = new Set([
        ...demand0Data.map((d) => d.id),
        ...demand1Data.map((d) => d.id),
      ]);
      const demandDiff: DemandWithSkuTags[] = [];
      demandIds.forEach((dId) => {
        const d0 = demand0Data.find((d) => d.id === dId);
        const d1 = demand1Data.find((d) => d.id === dId);
        if (d0 !== undefined || d1 !== undefined) {
          demandDiff.push({
            ...(d0 || d1),
            quantity: (d0?.quantity || 0) - (d1?.quantity || 0),
            revenue: (d0?.revenue || 0) - (d1?.revenue || 0),
            runId: "Run Delta (1 - 2)",
          } as DemandWithSkuTags);
        }
      });

      return [...demand0Data, ...demand1Data, ...demandDiff];
    } else {
      return [...demand0Data];
    }
  }, [runId1, demand0Data, demand0DataLength, demand1Data, demand01ataLength]);

  return (
    <DemandDiffTableComponent
      diffParams={diffParams}
      hasLicence={hasLicence}
      loading={customerLoading}
      dataLoading={demand0Loading || demand1Loading || skuTagsLoading}
      demand={demand}
      maxRevenue={demand0.getMinMax().maxRevenue}
      maxQuantity={demand0.getMinMax().maxQuantity}
      setPivotObject={setPivotObject}
      hasProductCategory={
        customerData?.getMyCustomer?.features?.SkuHierarchyProductCategory ||
        false
      }
      hasProductType={
        customerData?.getMyCustomer?.features?.SkuHierarchyProductType || false
      }
      hasProductFamily={
        customerData?.getMyCustomer?.features?.SkuHierarchyProductFamily ||
        false
      }
      hasProductTitle={
        customerData?.getMyCustomer?.features?.SkuHierarchyProductTitle || false
      }
      modeString={modeString}
    />
  );
};
