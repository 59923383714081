import Papa from "papaparse";

export type SkuTag = {
  sku: string;
  tag: string;
  key?: string;
  value: string;
};

export class SkuTagsFromCsv {
  private skuTags: SkuTag[] = [];
  private setLoading: (v: boolean) => void;

  constructor(setLoading: (v: boolean) => void) {
    this.setLoading = setLoading;
  }

  async fetch(csvUrl: string) {
    this.setLoading(true);
    Papa.parse<SkuTag>(csvUrl, {
      download: true,
      worker: true,
      header: true,
      dynamicTyping: true,
      step: (results) => {
        const { sku, key, value } = results.data;
        if (
          key !== null &&
          key !== undefined &&
          key !== "undefined" &&
          key !== "NULL"
        ) {
          this.skuTags.push({
            sku,
            tag: key || "",
            value:
              value !== null &&
              value !== undefined &&
              value !== "undefined" &&
              value !== "NULL"
                ? value
                : "Unknown",
          });
        }
      },
      complete: () => {
        this.setLoading(false);
      },
    });
  }

  toDict(): Record<string, Record<string, string>> {
    const skuTagDict: Record<string, Record<string, string>> = {};
    this.skuTags.forEach((skuTag) => {
      const { sku, tag, value } = skuTag;
      skuTagDict[sku] = skuTagDict[sku] || {};
      skuTagDict[sku][tag] = value;
    });
    return skuTagDict;
  }
}
